import Paper from "@mui/material/Paper";
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Alert,
  Tooltip,
  Typography,
  Button, Box, TextField, CircularProgress
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { fetchTransport, http } from "../../../api/base/http";
import { API_HOST } from "../../../api/vars";
import { AuthError, CarAuctionsAPI, ISyncJobRow } from "../../../api/carAuctionsAPI";
import { Add, AddBox, AddCard, DownloadDone, Downloading } from "@mui/icons-material";
import { logout } from "./index";

interface ISyncTracker {
  tracking: boolean;
}

function getDate(daysAgo: number): string {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-indexed, so +1 is needed
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function isInTheFutureOrToday(date: string): boolean {

  const currentDate = new Date();
  const inputParsedDate = new Date(date);

  // Setting the time of both dates to midnight for comparison
  currentDate.setHours(0, 0, 0, 0);
  inputParsedDate.setHours(0, 0, 0, 0);

  return inputParsedDate >= currentDate;
}

export default function SyncTracker(props: ISyncTracker) {
  const [rows, setRows] = useState<ISyncJobRow[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [dateFrom, setDateFrom] = useState<string>(getDate(1));
  const [dateTo, setDateTo] = useState<string>(getDate(1));


  const httpClient = http(fetchTransport()).createClient(API_HOST);
  const carAuctionsAPI = CarAuctionsAPI(httpClient);


  useEffect(() => {
    if (props.tracking) {
      let interval = setInterval(() => {
        // console.log("Fetching");
        setIsFetching(true);
        carAuctionsAPI
          .syncJobPing()
          .then((rows: ISyncJobRow[]) => {
            setRows(rows);
            setIsFetching(false);
            setError('');
          })
          .catch(e => {
            if (e instanceof AuthError) {
              logout();
            }
            setError(e);
            console.error(e);
          })

      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }

  }, [props.tracking])

  const statusSeverity = (status: string) => {
    switch(status) {
      case "PENDING":
        return "info";
      case "IN_PROGRESS":
        return "warning";
      case "FAIL":
        return "error";
      case "SUCCESS":
        return "success";
      default:
        return "info";
    }
  }


  if (rows.length === 0) {
    return <Box padding={3}>
      <CircularProgress />
    </Box>
  }


  return (
    <>
      {error !== '' ? <Alert severity="error" title="Something went wrong..." /> : (
        <>
          <Box display="flex" sx={{ m: 1 }}>
            <TextField
              value={dateFrom}
              title="from"
              type="date"
              size="small"
              onChange={(e) => {
                if (!isInTheFutureOrToday(e.target.value)) {
                  setDateFrom(e.target.value);
                } else {
                  alert("Date cannot be set to today or the future.");
                }
              }}
              sx={{ mr: 1 }}
            />
            <TextField
              value={dateTo}
              title="from"
              type="date"
              size="small"
              onChange={(e) => {
                if (!isInTheFutureOrToday(e.target.value)) {
                  setDateTo(e.target.value);
                } else {
                  alert("Date cannot be set to today or the future.");
                }
              }}
              sx={{ mr: 1 }}
            />
            <Button
              variant="outlined"
              size="small"
              sx={{ mr: 1, height: "37px" }}
              onClick={() => {
                if (new Date(dateFrom) > new Date(dateTo)) {
                  alert("Date from cannot be later than the date to");
                  return;
                }
                carAuctionsAPI
                  .syncJobAdd(dateFrom, dateTo)
                  .then()
                  .catch(e => {
                    if (e instanceof AuthError) {
                      logout();
                    }
                  })
              }}
            >
              <Add />
            </Button>
            <Button disabled sx={{ ml: "auto" }}><Typography sx={{ mr: 1 }}>Status:</Typography>{isFetching ? <Downloading /> : <DownloadDone />}</Button>
          </Box>
          {/*<LinearProgress style={{*/}
          {/*  opacity: (isFetching) ? 1 : 0,*/}
          {/*  transition: "opacity 1s cubic-bezier(0.1, 1, 0.2, 1)"*/}
          {/*}} />*/}
          <TableContainer component={Paper}>
            <Table size="small" className="notranslate">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Od</TableCell>
                  <TableCell>Do</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Ilość podejść</TableCell>
                  <TableCell>Wiadomość</TableCell>
                  <TableCell>Pobranych</TableCell>
                  <TableCell>Dodanych</TableCell>
                  <TableCell>Zaktualizowanych</TableCell>
                  <TableCell>Data utworzenia</TableCell>
                  <TableCell>Data modyfikacji</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.date_from}</TableCell>
                    <TableCell>{item.date_to}</TableCell>
                    <TableCell>
                      <Alert severity={statusSeverity(item.status)} icon={false} sx={{ px: 1, py: 0, display: "flex", justifyContent: "center" }}>
                        {item.status}
                      </Alert>
                    </TableCell>
                    <TableCell>{item.attempts}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>
                      {item.message ? (
                        <Tooltip title={item.message}>
                          <Typography textOverflow="ellipsis" overflow="hidden">{item.message}</Typography>
                        </Tooltip>
                      ) : "—"}
                    </TableCell>
                    <TableCell>{item.auctions_processed}</TableCell>
                    <TableCell>{item.auctions_added}</TableCell>
                    <TableCell>{item.auctions_updated}</TableCell>
                    <TableCell>{item.created_at}</TableCell>
                    <TableCell>{item.updated_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

    </>
  )
}

