import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, CircularProgress,
  Container,
  Divider,
  Grid, Icon, InputAdornment, Link, MenuItem, Select,
  Stack, Table, TableBody, TableCell, TableRow, TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowRight, ArrowRightAlt, ChevronRight, Email, Search } from "@mui/icons-material";
import CarTile from "../../components/CarTile";
import { fetchTransport, http, IHttpClient } from "../../api/base/http";
import { CarAuctionsAPI, ICarAuction } from "../../api/carAuctionsAPI";
import { CarSearch } from "src/components/CarSearch";
import { searchSubmit } from "src/components/CarSearch/utils";
import { API_HOST } from "src/api/vars";
import { formatAmount } from "../../components/CarTile/utils";


export default function Home() {
  const [auctionsAmount, setAuctionsAmount] = useState<number>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const httpClient = http(fetchTransport()).createClient(API_HOST);
  const carAuctionsAPI = CarAuctionsAPI(httpClient);
  const [
    recentlySold,
    setRecentlySold
  ] = useState<ICarAuction[]|"loading"[]>([
    'loading',
    'loading',
    'loading',
    'loading',
    'loading',
    'loading',
  ]);
  useEffect(() => {
    carAuctionsAPI
      .recentlySold()
      .then(carAuctions => {
        setRecentlySold(carAuctions)
        console.log(carAuctions);
      }).catch(error => {
      console.error(error);
    })
    carAuctionsAPI
      .auctionsAmount()
      .then(amount => {
        setAuctionsAmount(amount)
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  const onSearchSubmit = () => {
    searchSubmit(searchTerm)
  }
  return (
    <>
      <Container maxWidth="md" sx={{
        pb: 5,
        backgroundImage: "url('/static/images/map.png')",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "100px",
        backgroundSize: "contain"
      }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          // spacing={3}
          sx={{
            pt: { sm: 5 }
          }}
        >
          <Grid item xs={12} sx={{ my: 5 }}>
            <Typography variant="h1" textAlign="center" sx={{ mb: 2 }}>
              Historia sprzedaży pojazdów na aukcjach Copart i IAAI
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              Sprawdź historię sprzedaży aukcji pojazdów powypadkowych Copart, IAAI
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              placeholder="Wpisz numer VIN lub LOT"
              name="bidhistory-search"
              fullWidth
              size="small"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSearchSubmit();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ maxWidth: "500px", ml: "auto" }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ ml: 1, mr: "auto", height: "100%" }}
              onClick={() => {
                searchSubmit(searchTerm)
              }}
            >Szukaj</Button>
          </Grid>

          <Grid item xs={12} sx={{ mt: 5, mb: 4 }}>
            <Typography variant="h2" textAlign="center" className="notranslate">
              {auctionsAmount ? formatAmount(auctionsAmount) : <CircularProgress />}
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              pojazdów w naszej bazie
            </Typography>
            <Typography variant="h4" textAlign="center" sx={{ mt: 5 }}>
              Wyszukiwanie zaawansowane
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <CarSearch
            gridStack={false}
            showClearButton={false}
            defaultValues={{}}
            onSubmit={(
              make: string|null,
              model: string|null,
              yearFrom: string|null,
              yearTo: string|null
            ) => {
              const params = new URLSearchParams();
              if (make) {
                params.set('make', make);
              }
              if (model) {
                params.set('model', model);
              }
              if (yearFrom) {
                params.set('yearFrom', yearFrom);
              }
              if (yearTo) {
                params.set('yearTo', yearTo);
              }
              window.location.href = '/app/car/search?' + params.toString();
            }}
          />
        </Grid>
        <Box sx={{ py: 2, display: { xs: 'none', md: 'block' } }}>&nbsp;</Box>
        <Grid container sx={{ mb: 5, mx: "auto" }}>
          {[
            {
              iconSrc: "/static/images/icons/icon-1.svg",
              heading: "Historia ofert",
              subtitle: "Zobacz ostateczne oferty wylicytowanych pojazdów"
            },
            {
              iconSrc: "/static/images/icons/icon-2.svg",
              heading: "Szczegóły pojazdu",
              subtitle: "Sprawdź wszystkie informacje na temat wylicytowanego pojazdu"
            },
            {
              iconSrc: "/static/images/icons/icon-3.svg",
              heading: "Informacje o sprzedawcy",
              subtitle: "Zobacz, kto był sprzedawcą wylicytowanego pojazdu"
            },

          ].map((item, key) => (
            <Grid
              key={key}
              item
              xs={12}
              md={4}
              display="flex"
              alignItems="center"
              sx={{
                flexDirection: { xs: "row", md: "column" },
                textAlign: { xs: "left", md: "center"  },
                mb: { xs: 3, md: 0 }
              }}
            >
              <Icon
                sx={{
                  mr: { xs: 2, md: 0 },
                  mb: { xs: 0, md: 2 },
                  minWidth: "64px",
                  minHeight: "64px"
                }}>
                <img src={item.iconSrc} alt={item.heading} />
              </Icon>
              <Box>
                <Typography variant="h4" sx={{ mb: 1 }}>{item.heading}</Typography>
                <Typography>{item.subtitle}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ py: 2, display: { xs: 'none', md: 'block' } }}>&nbsp;</Box>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} >
            <Typography variant="h3" textAlign="center" sx={{ mb: 1 }}>
              Ostatnio sprzedane
            </Typography>
          </Grid>
          {recentlySold.map((carAuction, key) => (
            <CarTile
              key={key}
              carAuction={carAuction}
            />
          ))}
        </Grid>
        {/* <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={8}>
            <Typography variant="h3" sx={{ mb: 3 }}>Ostatnio dodane</Typography>
            <Typography fontSize="x-small" variant="subtitle2">
              2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721 1G1YA2D42P5109624 2C3CDZAG7PH549318 2FMPK4J93KBB48721
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <img src="/static/images/white-car.png" />
          </Grid>
        </Grid> */}
      </Container>
      <Container maxWidth={false} disableGutters>
        <Grid container bgcolor="#2B3236">
          <Grid item xs={12} sx={{ py: 3 }}>
            <Container maxWidth="md" sx={{ my: 3 }}>
              <Typography sx={{ mb: 2 }} color="white" variant="h3">O nas</Typography>
              <Typography sx={{ mb: 2 }} color="white" fontWeight="bold">BidHistory.vin - to bezpłatna usługa wyszukiwania ofert i historii sprzedaży pojazdów powypadkowych na aukcjach.</Typography>
              <Typography sx={{ mb: 2 }} color="white">Nasz zasób zapewnia dostęp do aukcji pojazdów powypadkowych w USA i Kanadzie. W naszym katalogu możesz zobaczyć wszystkie informacje o sprzedawanych pojazdach na aukcjach wraz ze wszystkimi szczegółowymi informacjami (VIN, informacje o sprzedającym, oferty, uszkodzenia, zdjęcia i specyfikacje pojazdów). Możesz także użyć filtrów w katalogu według numeru aukcji, modelu, marki i zakresu ofert, aby znaleźć potrzebne pojazdy.</Typography>
              <Typography sx={{ mb: 2 }} color="white" fontWeight="bold">Bezpłatne sprawdzanie historii sprzedaży pojazdów na aukcjach po numerze VIN.</Typography>
              <Typography color="white">Każdego dnia nasz serwis przetwarza na aukcjach ponad 50 tysięcy pozycji. W naszej bazie danych dostępnych jest ponad 10 milionów pojazdów do bezpłatnego wyszukiwania według numeru VIN. Możesz łatwo sprawdzić historię sprzedaży na aukcjach dowolnego pojazdu, a także wyświetlić wszystkie szczegółowe informacje o nadchodzących aukcjach. Na naszej stronie masz dostęp do największych aukcji samochodowych, takich jak Copart, IAAI. Z pomocą naszego serwisu możesz uzyskać więcej informacji o pojeździe kupując go z aukcji.</Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}