import { IHttpClient } from "./base/http";
import { getAuthToken } from "../utils/authentication";


export class AuthError extends Error {

}

export interface IFilter {
  [key:string]: {
    operator: "="|">"|"<"|"in"|"<="|">=",
    value: any
  }
}

interface IPage {
  page: number;
  size: number;
}

export interface ISort {
  field: keyof ICarAuction | "lot_info__year";
  order: "asc"|"desc";
}


export interface ICarAuction {
  id: string;
  site: string;
  lot_id: string;
  vin: string;
  sale_status: string;
  sale_date: string;
  purchase_price: number;
  buyer_state: string | null;
  buyer_country: string | null;
  created_at: string;
  updated_at: string;
  lot_info: {
    lot_id: string;
    site: string;
    salvage_id: string | null;
    odometer: number;
    cost_priced: number;
    cost_repair: number;
    year: string;
    cylinders: string;
    state: string;
    vehicle_type: string;
    auction_type: string;
    make: string;
    model: string;
    series: string;
    loss: string;
    damage_pr: string;
    damage_sec: string | null;
    keys: string;
    odobrand: string;
    fuel: string;
    drive: string;
    transmission: string;
    color: string;
    status: string;
    title: string;
    vin: string;
    engine: string;
    link_img_hd: string[];
    image_360: string | null;
    location: string;
    document: string;
    seller: string | null;
  };
  display_auction: number;
  display_vin: number;
}

export interface IResultPageData {
  page: number,
  size: number,
  totalResults: number,
  totalPages: number
}

export interface IAdminCounts {
  auctions_amount: number;
  auctions_updated_last_month: number;
  auctions_added_last_month: number;
  hidden_auctions: number;
}

export interface ISyncJobRow {
  id: number;
  date_from: string;
  date_to: string;
  status: string;
  attempts: number;
  message: string;
  auctions_processed: number;
  auctions_added: number;
  auctions_updated: number;
  created_at: string;
  updated_at: string;
}

export const CarAuctionsAPI = (httpClient: IHttpClient) => {
  return {
    filter: (
      filter:IFilter,
      page:IPage = {
        page: 1,
        size: 10
      },
      sort:ISort = {
        field: "created_at",
        order: "desc"
      },
      randomOrder: boolean = false,
      showHidden: boolean = false
    ): Promise<{
      carAuctions: ICarAuction[],
      page: IResultPageData
    }> => {
      return new Promise(async (resolve, reject) => {
        try {
          let body = {
            filter: filter,
            page: page,
            sort: sort,
            randomOrder: Number(randomOrder)
          };
          if (showHidden) {
            body["c13d23675b7a621212c3a6bb07e0e8df"] = 1
          }
          const result = await httpClient.post({
            path: '/dev/car/filter',
            body: body
          });
          const carAuctions: ICarAuction[] = [];

          result.data.results.forEach((element: ICarAuction) => {
            if (element.lot_info.title.endsWith(" null")) {
              element.lot_info.title = element.lot_info.title.substring(
                0, 
                element.lot_info.title.length - 5
              )
            }
            carAuctions.push(element);
          });

          // console.log(
          //   {
          //     carAuctions: carAuctions,
          //     page: result.data.page
          //   }
          // );
          resolve({
            carAuctions: carAuctions,
            page: result.data.page
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    recentlySold: (): Promise<ICarAuction[]> => {
      
      return new Promise(async (resolve, reject) => {
        try {
          
          const result = await httpClient.post({
            path: '/dev/car/recently-sold',
            body: {}
          });
          const carAuctions: ICarAuction[] = [];

          result.data.results.forEach((element: ICarAuction) => {
            if (element.lot_info.title.endsWith(" null")) {
              element.lot_info.title = element.lot_info.title.substring(
                0,
                element.lot_info.title.length - 5
              )
            }
            carAuctions.push(element);
          });

          resolve(carAuctions);
        } catch (e) {
          reject(e);
        }
      });

    },
    similarByMake: (make: string): Promise<ICarAuction[]> => {

      return new Promise(async (resolve, reject) => {
        try {

          const result = await httpClient.post({
            path: '/dev/car/similar',
            body: {
              "make": make
            }
          });
          const carAuctions: ICarAuction[] = [];

          result.data.results.forEach((element: ICarAuction) => {
            if (element.lot_info.title.endsWith(" null")) {
              element.lot_info.title = element.lot_info.title.substring(
                0,
                element.lot_info.title.length - 5
              )
            }
            carAuctions.push(element);
          });

          resolve(carAuctions);
        } catch (e) {
          reject(e);
        }
      });

    },
    auctionsAmount: (): Promise<number> => {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await httpClient.get({
            path: '/dev/car/auctions-amount',
          });
          resolve(result.data.auctions_amount);
        } catch (e) {
          reject(e);
        }
      })
    },
    adminCounts: (): Promise<IAdminCounts> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!getAuthToken()) {
            throw new AuthError("No token in storage");
          }
          const result = await httpClient.get({
            path: '/dev/admin/auction-counts',
            headers: {
              "Authorization": 'Bearer ' + getAuthToken()
            }
          });
          resolve(result.data.counts as IAdminCounts);
        } catch (e) {
          reject(e);
        }
      })
    },
    updateDisplayAuction: (
      id: number, 
      display_auction: boolean, 
    ): Promise<boolean> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!getAuthToken()) {
            throw new AuthError("No token in storage");
          }
          
          const result = await httpClient.post({
            path: '/dev/admin/car/update',
            body: {
              id: id,
              update: {
                display_auction: display_auction ? 1 : 0
              }
            },
            headers: {
              "Authorization": 'Bearer ' + getAuthToken()
            }
          });

          // console.log("Update result", result);

          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    updatePrice: (
      id: number,
      purchase_price: number
    ): Promise<boolean> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!getAuthToken()) {
            throw new AuthError("No token in storage");
          }

          const result = await httpClient.post({
            path: '/dev/admin/car/update',
            body: {
              id: id,
              update: {
                purchase_price: purchase_price
              }
            },
            headers: {
              "Authorization": 'Bearer ' + getAuthToken()
            }
          });

          // console.log("Update result", result);

          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    syncJobAdd: (
      dateFrom: string,
      dateTo: string
    ): Promise<boolean> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!getAuthToken()) {
            throw new AuthError("No token in storage");
          }

          await httpClient.post({
            path: '/dev/admin/report/sync-job/add',
            body: {
              date_from: dateFrom,
              date_to: dateTo
            },
            headers: {
              "Authorization": 'Bearer ' + getAuthToken()
            }
          });

          resolve(true);
        } catch (e) {
          reject(e);
        }
      })
    },
    syncJobPing: (): Promise<ISyncJobRow[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!getAuthToken()) {
            throw new AuthError("No token in storage");
          }

          const result = await httpClient.post({
            path: '/dev/admin/report/sync-job/ping',
            headers: {
              "Authorization": 'Bearer ' + getAuthToken()
            }
          });



          resolve(result.data);
        } catch (e) {
          reject(e);
        }
      })
    },
    authenticate: (user: string, password: string): Promise<string> => {
      return new Promise(async (resolve, reject) => {
        try {

          const result = await httpClient.post({
            path: '/dev/admin/authenticate',
            body: {
              user,
              password
            }
          });

          resolve(result.data.token);
        } catch (e) {
          reject(e);
        }
      })
    },
    isAuthenticated: (): Promise<boolean> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!getAuthToken()) {
            throw new AuthError("No token in storage");
          }

          const result = await httpClient.get({
            path: '/dev/admin/is-authenticated',
            headers: {
              "Authorization": 'Bearer ' + getAuthToken()
            }
          });

          resolve(result.data === "OK");

        } catch (e) {
          reject(e);
        }
      })
    }
  }
}