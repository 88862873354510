import { Box, Button, InputAdornment, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { isStringANumber } from "../../../components/CarTile/utils";

interface IPriceInput {
  defaultPrice: number;
  onPriceUpdate: (newPrice: number) => void;
}

export default function PriceInput(
  props: IPriceInput
) {
  const [price, setPrice] = useState<number>(props.defaultPrice);

  return (
    <Box display="flex">

      <TextField
        // defaultValue={props.defaultPrice}
        value={price}
        onChange={(e) => {
          if (isStringANumber(e.target.value)) {
            setPrice(parseInt(e.target.value))
          }
        }}
        size="small"
        InputProps={{
          // sx: {
          //   border: "1px solid black",
          // },
          endAdornment:
            <InputAdornment position="end" sx={{ display: { xs: 'none', md: 'flex' } }}>
              USD
            </InputAdornment>,
        }}
      />
      {price !== props.defaultPrice &&
        <Button
          size="small"
          variant="contained"
          sx={{ ml: 1 }}
          onClick={() => {
            props.onPriceUpdate(price);
          }}
        >Zapisz</Button>
      }
    </Box>
  )
}