import React, { useContext, useEffect } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip, Typography, Select, MenuItem
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Header from "../Header";
import { Email } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { setCookie, getCookie, removeCookie } from "typescript-cookie";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 100vw;
        min-width: 100vw;
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <Drawer
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        hideBackdrop={true}
        transitionDuration={0}
      >
        <SidebarWrapper
          // sx={{
          //   background: "white"
          // }}
        >
          <Header />
          <Box sx={{ py: 3 }}>&nbsp;</Box>
          <Box sx={{ my: 5 }} textAlign="center">
            <Typography variant="subtitle2" className="capitalize" sx={{ mb: 2 }}>Skontaktuj się z nami</Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Email />
              <Typography sx={{ ml: 1 }}>contact@bidhistory.vin</Typography>
            </Box>
          </Box>
          <Divider sx={{ mx: 2 }} />
          <Box display="flex">
            {/*<div id="google_translate_element"></div>*/}
            <Select
              className="notranslate"
              defaultValue={getCookie("googtrans").replace('/pl/', '')}
              sx={{ mx: 'auto', mt: 3, zIndex: 10}}
              onChange={(event) => {
                setCookie('googtrans', '/pl/' + event.target.value, { sameSite: 'strict' });
                navigate(0);
              }}
            >
              <MenuItem className="notranslate" value="en">EN</MenuItem>
              <MenuItem className="notranslate" value="uk">UA</MenuItem>
              <MenuItem className="notranslate" value="ru">RU</MenuItem>
              <MenuItem className="notranslate" value="pl">PL</MenuItem>
            </Select>
          </Box>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
