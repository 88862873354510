import React, { useContext, useState } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme, Link, Typography, Container, Grid, TextField, InputAdornment, Button, Select, MenuItem
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderMenu from './Menu';
import { Download, Email, GitHub, LinkedIn, Search } from "@mui/icons-material";
import HeaderSearch from "./Buttons/Search";
import { searchSubmit } from "../../../components/CarSearch/utils";
import { setCookie, getCookie, removeCookie } from "typescript-cookie";
import { useNavigate } from "react-router";

const HeaderWrapper = styled(Box)(
  // height: ${theme.header.height};
  ({ theme }) => `
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);


function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const onSearchSubmit = () => {
    searchSubmit(searchTerm)
  }
  const navigate = useNavigate();

  return (
    <HeaderWrapper display="flex" alignItems="center" sx={{
      height: {
        xs: "70px",
        md: "80px"
      },
      padding: {
        xs: 0,
        md: 2
      },
      position: "sticky",
      top: 0
    }}>
      <Container sx={{
        display: "flex",
        alignItems: "center"
      }}>
        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  display="flex"*/}
        {/*  // divider={<Divider orientation="vertical" flexItem />}*/}
        {/*  alignItems="center"*/}
        {/*  spacing={2}*/}
        {/*  sx={{*/}
        {/*    // display: { sm: 'flex', xs: 'none' }*/}
        {/*  }}*/}
        {/*>*/}
        <Link href="/">
          <img src="/static/images/bidhistory-logo.svg" />
        </Link>

        <TextField
          variant="outlined"
          placeholder="Wpisz numer VIN lub LOT"
          name="bidhistory-search"
          fullWidth
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSearchSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{ maxWidth: "500px", ml: "auto", display: { xs: 'none', md: 'block' } }}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 1, mr: "auto", height: "37px", display: { xs: 'none', md: 'block' } }}
          onClick={() => {
            searchSubmit(searchTerm)
          }}
        >Szukaj</Button>

        {/*</Stack>*/}
        <Box display="flex" alignItems="center" sx={{ ml: "auto" }}>
          <Select
            className="notranslate"
            defaultValue={getCookie("googtrans").replace('/pl/', '')}
            sx={{ display: { xs: 'none', lg: 'block' } }}
            size="small"
            onChange={(event) => {
              setCookie('googtrans', '/pl/' + event.target.value, { sameSite: 'strict' });
              navigate(0);
            }}
          >
            <MenuItem className="notranslate" value="en">EN</MenuItem>
            <MenuItem className="notranslate" value="uk">UA</MenuItem>
            <MenuItem className="notranslate" value="ru">RU</MenuItem>
            <MenuItem className="notranslate" value="pl">PL</MenuItem>
          </Select>
          {/*<HeaderButtons />*/}
          {/*<HeaderUserbox />*/}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <HeaderSearch />
          </Box>
          <Box
            // component="span"
            sx={{
              display: { lg: 'none', xs: 'inline-block' }
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton onClick={toggleSidebar}>
                {!sidebarToggle ? (
                  <MenuTwoToneIcon />
                ) : (
                  <CloseTwoToneIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Container>
    </HeaderWrapper>
  );
}

export default Header;
