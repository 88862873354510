
import getCountryISO2 from 'country-iso-3-to-2'

export function formatPrice(purchase_price: number) {
  return  new Intl.NumberFormat('en-US', {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  }).format(purchase_price);
}

export function formatDate(date: string | number | Date) {
  const formatter = new Intl.DateTimeFormat("en-GB");
  return formatter.format(new Date(date));
}

export function formatAmount(amount: number) {
  return new Intl.NumberFormat().format(amount).toString().replaceAll(",", " ")
}

export function displayCountry(buyer_country: string) {
  let regionNames = new Intl.DisplayNames(['pl'], {type: 'region'});
  return regionNames.of(getCountryISO2(buyer_country));
}

export function getAuctionLogoPath(site: string) {
  switch(site) {
    case "1":
      return "/static/images/copart.svg";
    case "2":
      return "/static/images/IAAI.svg";
    default:
      console.error("SiteID: [" + site + "] not recognized.")
      return "";
  }
}

export function getAuctionName(site: string) {
  switch(site) {
    case "1":
      return "Copart";
    case "2":
      return "IAAI";
    default:
      console.error("SiteID: [" + site + "] not recognized.")
      return "";
  }
}

export function isStringANumber(string: string) {
  return /^[0-9]*$/.test(string);
}