

const AUTH_TOKEN_STORAGE_KEY = "bidHistory_adminBearerToken";

export function setAuthToken(token: string) {
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
}

export function deleteAuthToken() {
  localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
}

export function getAuthToken(): string|null {
  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return token ?? null;
}