import {
  Avatar, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip, CircularProgress,
  Container, Dialog, Divider,
  Grid,
  Icon,
  InputAdornment, LinearProgress, Modal, Table, TableBody, TableCell, TableRow,
  TextField, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminTable, { IAdminTableRow } from './AdminTable';
import { fetchTransport, http } from "src/api/base/http";
import { API_HOST } from "src/api/vars";
import { AuthError, CarAuctionsAPI, IAdminCounts, IResultPageData } from "src/api/carAuctionsAPI";
import {
  AddBox,
  CalendarMonth,
  CloudSync,
  DoDisturbOn,
  Downloading,
  Edit, Logout,
  Search,
  Stop,
  Storage
} from "@mui/icons-material";
import { formatAmount, formatDate } from "../../../components/CarTile/utils";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import SyncTracker from "./SyncTracker";
import { deleteAuthToken, getAuthToken } from "../../../utils/authentication";

let alreadyAlerted = false;
export const logout = () => {
  if (!alreadyAlerted) {
    alert("Your session expired.");
    alreadyAlerted = true;
  }
  window.location.href = '/admin/login';
}

export default function Manage() {

  const httpClient = http(fetchTransport()).createClient(API_HOST);
  const carAuctionsAPI = CarAuctionsAPI(httpClient);
  
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<IAdminTableRow[]>([]);
  const [searchVin, setSearchVin] = useState<string>("");
  const [searchVINs, setSearchVINs] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageData, setPageData] = useState<IResultPageData>();
  const [adminCounts, setAdminCounts] = useState<IAdminCounts>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  // const [auctionsAmount, setAuctionsAmount] = useState<number>();
  // const [auctionsAddedAmount, setAuctionsAddedAmount] = useState<number>();
  // const [auctionsUpdatedAmount, setAuctionsUpdatedAmount] = useState<number>();
  // const [auctionsHiddenAmount, setAuctionsHiddenAmount] = useState<number>();

  const getCounts = () => {
    carAuctionsAPI
      .adminCounts()
      .then(adminCounts => {
        setAdminCounts(adminCounts)
      })
      .catch(error => {
        if (error instanceof AuthError) {
          logout()
        }
        console.error(error);
      })
  }

  useEffect(() => {
    if (!getAuthToken()) {
      window.location.href = '/admin/login';
    } else {
      carAuctionsAPI
        .isAuthenticated()
        .then((authenticated) => {
          if (authenticated) {
            setIsAuthenticated(true);
            getCounts();
          } else {
            window.location.href = '/admin/login';
          }
        })
        .catch((e) => {
          window.location.href = '/admin/login';
        })
    }
  }, []);

  const onDisplayHideAuction = (id: number, display: boolean) => {
    carAuctionsAPI
      .updateDisplayAuction(id, display)
      .then(result => {
        // Refresh the table
        onSearchSubmit();
        getCounts();
      })
      .catch(error => {
        if (error instanceof AuthError) {
          logout()
        } else {
          alert(error);
        }
        console.error(error);
      })
  }

  const onPriceUpdate = (id: number, newPrice: number) => {
    carAuctionsAPI
      .updatePrice(id, newPrice)
      .then(result => {
        // Refresh the table
        onSearchSubmit();
        getCounts();
      })
      .catch(error => {
        if (error instanceof AuthError) {
          logout()
        } else {
          alert(error);
        }
        console.error(error);
      })
  }

  const onSearchSubmit = () => {
    if (searchVINs.length === 0) {
      setLoading(false);
      setPageData(undefined);
      setRows([]);
      return;
    }
    setLoading(true);
    carAuctionsAPI
      .filter(
        {
          vin: {
            operator: "in",
            value: searchVINs
          }
        },
        {
          page: pageNumber,
          size: pageSize
        },
        {
          field: "created_at",
          order: "desc"
        },
        false,
        true
      )
      .then(response => {
        const rows = [];
        response.carAuctions.forEach(carAuction => {
          rows.push({
            id: carAuction.id,
            vin: carAuction.vin,
            lot_id: carAuction.lot_id,
            make: carAuction.lot_info.make,
            purchase_price: carAuction.purchase_price,
            sale_date: carAuction.sale_date,
            display_auction: carAuction.display_auction,     
          })
        })
        setPageData(response.page);
        setRows(rows);
        setLoading(false);
      })
      .catch(error => {
        if (error instanceof AuthError) {
          logout()
        } else {
          alert("Something went wrong... Please check the console and contact a developer.");
        }
        console.error(error);
        setLoading(false);
      })
  }

  const onChangePage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  }

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setPageNumber(1);
  }


  const updateSearchVINs = () => {
    const newItems = [];
    searchVin.split(',').map(item => {
      if (!item) return;
      newItems.push(item);
    })
    console.log(newItems);
    if (newItems.length > 0) {
      let somethingAdded = false;
      const allVins = [...searchVINs];
      newItems.map(item => {
        const vin = item.trim();
        if (!allVins.includes(vin)) {
          allVins.push(vin);
          somethingAdded = true;
        }
      })
      if (somethingAdded) {
        setSearchVINs(allVins);
        setPageNumber(1);
      }
    }
    setSearchVin("");
  }

  useEffect(() => {
    onSearchSubmit();
  }, [pageSize, pageNumber, searchVINs]);


  const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);

  if (!isAuthenticated) {
    return (<><LinearProgress sx={{ m: 5 }} /></>);
  }

  return (
    <>
      <Container className="notranslate" maxWidth="lg" sx={{
        py: 5,
        mb: 5,
      }}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} display="flex" justifyContent="end">
            <Button variant="contained" onClick={() => {setOpenSyncModal(true)}}>
              <Downloading sx={{ mr: 1 }} />
              Otwórz detale synchronizacji
            </Button>
            <Divider orientation="vertical" sx={{ mx: 1 }} />
            <Button variant="contained" color="warning" onClick={() => {
              if (confirm("Wyloguj?")) {
                deleteAuthToken();
                window.location.href = "/admin/login";
              }
            }}>
              <Logout sx={{ mr: 1 }} />
              Wyloguj
            </Button>
          </Grid>
          <Dialog open={openSyncModal} onClose={() => {setOpenSyncModal(false)}} maxWidth="xl">
            <SyncTracker
              tracking={openSyncModal}
            />
          </Dialog>
          {/*<Grid item xs={12}>*/}
          {/*  <Divider sx={{ my: 2 }} />*/}
          {/*</Grid>*/}
        </Grid>
        <Grid container spacing={2}>
          {[
            { key: "auctions_amount", title: "Pojazdów w bazie", bgcolor: "gray", icon: <Storage /> },
            { key: "auctions_updated_last_month", title: "Aktualizacji w tym miesiącu", bgcolor: "#F5BA22", icon: <Edit /> },
            { key: "auctions_added_last_month", title: "Dodanych w tym miesiącu", bgcolor: "#1DB5A7", icon: <AddBox /> },
            { key: "hidden_auctions", title: "Ukrytych VIN", bgcolor: "#DC2626", icon: <DoDisturbOn /> }
          ].map((tile, i) => (
            <Grid item xs={12} md={6} lg={3} key={i}>
              <Card>
                <CardContent sx={{
                  display: "flex",
                  flexDirection: "row"
                }}>
                  <Avatar sx={{ mr: 2, bgcolor: tile.bgcolor }}>
                    {tile.icon}
                  </Avatar>
                  {adminCounts ? (
                    <Box>
                      <Typography variant="h4">
                        {formatAmount(adminCounts[tile.key])}
                      </Typography>
                      <Typography variant="subtitle1">
                        {tile.title}
                      </Typography>
                    </Box>
                  ) : (
                    <CircularProgress />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField 
              // label="VIN"
              variant="outlined"
              placeholder="Wpisz numer(y) VIN... Przykład: 2CNFLDE51B6291059, JN8CS1MWXDM171773, JTDKDTB30E1085204"
              name="bidhistory-search"
              size="small"
              value={searchVin}
              onChange={(e) => {
                setSearchVin(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  updateSearchVINs();
                  // if (pageNumber !== 1) {
                  //   // Just the pageNumber change is enough becasuse of useEffect
                  //   setPageNumber(1);
                  // } else {
                  //   onSearchSubmit();
                  // }
                }
              }}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "calc(100% - 200px)" }}
            />
            <Button 
              size="small" 
              sx={{ ml: 2, height: "100%" }}
              onClick={() => {
                updateSearchVINs()
                // if (pageNumber !== 1) {
                //   // Just the pageNumber change is enough becasuse of useEffect
                //   setPageNumber(1);
                // } else {
                //   onSearchSubmit();
                // }
              }}
            >
              Szukaj
            </Button>
            <Button 
              size="small" 
              color="error"
              sx={{ ml: 2, height: "100%" }}
              onClick={() => {
                setSearchVin("");
                setSearchVINs([]);
                // updateSearchVINs()
                // if (pageNumber !== 1) {
                //   // Just the pageNumber change is enough becasuse of useEffect
                //   setPageNumber(1);
                // } else {
                //   onSearchSubmit();
                // }
              }}
            >
              Wyczyść
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }}>
            {searchVINs.map((item, key) => (
              <Chip
                key={key}
                label={item}
                variant="outlined" 
                onDelete={() => {
                  setSearchVINs(searchVINs.filter(vin => vin !== item));
                  // console.log("QWE");
                }} 
                sx={{ ml: 1, mb: 1 }}
              />
            ))}
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <AdminTable
              loading={loading}
              rows={rows}
              pageData={pageData}
              onDisplayHideAuction={onDisplayHideAuction}
              onPriceUpdate={onPriceUpdate}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}