import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent, CardMedia,
  Grid, Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow, Tooltip,
  Typography,
  Link
} from "@mui/material";
import { ICarAuction } from "../../api/carAuctionsAPI";
import { makeStyles } from "@mui/styles";
import { formatDate, formatPrice, getAuctionLogoPath } from "./utils";

interface CarTileProps {
  carAuction: ICarAuction|"loading";
  gridColumns?: {
    md: number;
    sm: number;
    xs: number;
  }
}

export const CardTable =  styled(Table)(
  ({ theme }) => `
    color: ${theme.palette.text.primary};
    font-weight: ${theme.typography.fontWeightBold};
    & .MuiTableCell-root {
      padding-left: 0;
      padding-right: 0;
    }
    & .muted {
      color: ${theme.palette.text.secondary}
    }
    & td {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 135px;
    }
    & td.overflow {
      overflow: visible;
    }
`
);


export const useImageZoomStyles:any = makeStyles({
  imageZoom: {
    transition: 'transform 0.3s ease-in-out', // Apply the transition effect
    '&:hover': {
      transform: 'scale(1.1)', // Increase the scale on hover
    },
  },
});

const CarTile: FC<CarTileProps> = ({
 ...rest
}) => {
  if (rest.carAuction === "loading") {
    return (
      <Grid item xs={rest.gridColumns?.xs ?? 12} sm={rest.gridColumns?.sm ?? 6} md={rest.gridColumns?.md ?? 4}>
        <Skeleton height="415px" sx={{ marginY: "-85px" }}  />
      </Grid>
    )
  }
  const classes = useImageZoomStyles();
  const [imageIndex , setImageIndex] = useState<number>(0);

  const formattedPrice = formatPrice(rest.carAuction.purchase_price);
  const formattedSoldDate = formatDate(rest.carAuction.sale_date);
  let auctionLogo:string = getAuctionLogoPath(rest.carAuction.site);

  return (
    <Grid item xs={rest.gridColumns?.xs ?? 12} sm={rest.gridColumns?.sm ?? 6} md={rest.gridColumns?.md ?? 4}>
      
      <Link 
        className="noHover"
        href={"/app/car/sale-history/" + rest.carAuction.vin}
      >
        <Card
          sx={{
            '&:hover': {
              boxShadow: "0px 0px 12px 0px #8992a4"
            }
          }}>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              overflow="hidden" 
              className="rounded pointer" 
              // onClick={() => {
              //   if (rest.carAuction === 'loading') {
              //     return;
              //   }
              //   if (imageIndex + 1 >= rest.carAuction.lot_info.link_img_hd.length) {
              //     setImageIndex(0);
              //   } else {
              //     setImageIndex(imageIndex + 1);
              //   }
              // }}
            >
              <CardMedia
                component="img"
                // image={rest.carAuction.lot_info.link_img_hd[0] ?? "/static/images/car-blank.png"}
                image={
                  (rest.carAuction.lot_info?.link_img_hd ?? []).length === 0 ?
                  "/static/images/car-blank.png" :
                  rest.carAuction.lot_info.link_img_hd[0]
                }
                alt={rest.carAuction.lot_info.title}
                className={classes.imageZoom}
              />
            </Box>
            <Tooltip title={rest.carAuction.lot_info.title} placement="top">
              <Typography
                variant="h4"
                noWrap
                overflow="hidden"
                textOverflow="elipsis"
                sx={{ mt: 1 }}
              >{rest.carAuction.lot_info.title}</Typography>
            </Tooltip>
            <Box sx={{ display: "flex", justifyContent: "right", my: 1 }}>
              <CardMedia
                component="img"
                image={auctionLogo}
                sx={{
                  width: "auto",
                  maxHeight: "20px"
                }}
              />
            </Box>
            <CardTable size="small">
              <TableBody>
                <TableRow>
                  <TableCell className="muted">VIN</TableCell>
                  <TableCell align="right" className="overflow">
                    {rest.carAuction.vin}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Numer LOT</TableCell>
                  <TableCell align="right">{rest.carAuction.lot_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Data sprzedaży</TableCell>
                  <TableCell align="right">{formattedSoldDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography color="primary" className="capitalize">Cena sprzedaży</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="primary" fontSize="large" fontWeight="bold">
                      {formattedPrice}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </CardTable>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

CarTile.propTypes = {

};

export default CarTile;
