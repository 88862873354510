import { ICarAuction } from "../../api/carAuctionsAPI";
import { formatDate, getAuctionName } from './../../components/CarTile/utils';
import getCountryISO2 from 'country-iso-3-to-2'

export function generateCarDescription(carAuction: ICarAuction):string {
  let boughtIn = "";
  if (carAuction.buyer_country) {
    let regionNames = new Intl.DisplayNames(['pl'], {type: 'region'});
    boughtIn = ", kraj sprzedaży: " + regionNames.of(getCountryISO2(carAuction.buyer_country));
  }
  return "Pojazd " 
  + carAuction.lot_info.title 
  + " został sprzedany " + formatDate(onlyDateNoTime(carAuction.sale_date))
  + " na aukcji " + getAuctionName(carAuction.site)
  + " z numerem: " + carAuction.lot_id
  + boughtIn + ".";
}

export function onlyDateNoTime(sale_date: string) {
  return new Date(sale_date).toISOString().split('T')[0];
}