import {
  Box,
  Breadcrumbs, Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Icon,
  LinearProgress,
  Link,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { useParams } from "react-router";
import CarTile, { CardTable, useImageZoomStyles } from "../../components/CarTile";
import React, { useEffect, useState } from "react";
import {
  displayCountry,
  formatDate,
  formatPrice,
  getAuctionLogoPath,
  getAuctionName,
  isStringANumber
} from "../../components/CarTile/utils";
import { CarAuctionsAPI, ICarAuction } from "../../api/carAuctionsAPI";
import { fetchTransport, http } from "src/api/base/http";
import { API_HOST } from "src/api/vars";
import { ChevronRight, Email, Fullscreen } from "@mui/icons-material";
import Carousel from 'react-material-ui-carousel'
import { generateCarDescription, onlyDateNoTime } from './utils';
import { styled } from "@mui/system";
import { Helmet } from "react-helmet-async";
import NotFound from "../not-found";
import { searchSubmit } from "../../components/CarSearch/utils";



export const BulletList =  styled(List)(
  ({ theme }) => `
    list-style-type: disc;
    padding-left: 36px;
    & li {
      display: list-item;
      padding-top: 0;
      padding-bottom: 0;
    }
`);


export const MISSING = '–';
/* Display value */
function v(value) {
  if (!value || value === " " || value === "Unknown") {
    return MISSING;
  }
  return value;
}

export default function ListingPage() {
  const { vin } = useParams();
  const isSearchingForLotID = isStringANumber(vin);
  // console.log("vin = " + vin);

  const httpClient = http(fetchTransport()).createClient(API_HOST);
  const carAuctionsAPI = CarAuctionsAPI(httpClient);
  const [carAuction, setCarAuction] = useState<ICarAuction|"loading"|"notFound">("loading");
  const [similarAuctions, setSimilarAuctions] = useState<ICarAuction[]>([]);
  const [fullscreenSrc, setFullscreenSrc] = useState<string>("");
  const [carouselScreenShotIndex, setCarouselScreenShotIndex] = useState<number>(0);

  // const [notFound, setNotFound] = useState<boolean>(false);


  useEffect(() => {
    let filterBody;
    if (isSearchingForLotID) {
      filterBody = {
        "lot_id": {
          "operator": "=",
          "value": vin
        },
      }
    } else {
      filterBody = {
        "vin": {
          "operator": "=",
            "value": vin
        }
      }
    }
    carAuctionsAPI.filter(
      filterBody,
      {
        page: 1,
        size: 6
      },
      {
        field: "sale_date",
        order: "desc"
      }
    ).then(response => {
      const carAuctions = response.carAuctions;
      // setCarAuction(carAuction);
      if (carAuctions.length === 0) {
        setCarAuction("notFound");
        return;
      }
      if (isSearchingForLotID) {
        searchSubmit(carAuctions[0].vin);
        return;
      }
      setCarAuction(carAuctions[0]);

      carAuctionsAPI
        .similarByMake(carAuctions[0].lot_info.make)
        .then(similarResults => {
          // Iterating to make sure the current car isn't in the list
          // That's why 4 are requested even though we only need 3
          const newSimilar:ICarAuction[] = [];
          similarResults.map(auction => {
            if (auction.vin === vin) return;
            if (newSimilar.length < 3) {
              newSimilar.push(auction);
            }
          })
          setSimilarAuctions(newSimilar);
          // console.log(similarResults);
        })
      // console.log(carAuctions);
    }).catch(error => {
      alert("API error");
      setCarAuction("notFound");
      throw new Error(error);
    });
  }, []);


  if (carAuction === "loading") {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <LinearProgress sx={{ mt: 5, mb: 5 }} />
      </Container>
    )
  }

  if (carAuction === "notFound") {
    return (
      <NotFound />
    )
  }

  const formattedPrice = formatPrice(carAuction.purchase_price);
  const formattedSoldDate = formatDate(carAuction.sale_date);
  let auctionLogo:string = getAuctionLogoPath(carAuction.site);
  const title = carAuction.lot_info.title + ", VIN: " + vin;
  let damageInfo = "";
  if (v(carAuction.lot_info.damage_pr) !== MISSING && v(carAuction.lot_info.damage_sec) !== MISSING) {
    damageInfo = carAuction.lot_info.damage_pr + " i " + carAuction.lot_info.damage_sec;
  } else if (v(carAuction.lot_info.damage_pr) !== MISSING) {
    damageInfo = carAuction.lot_info.damage_pr;
  }
  // console.log(damageInfo, v(carAuction.lot_info.damage_pr) !== MISSING && v(carAuction.lot_info.damage_sec) !== MISSING, v(carAuction.lot_info.damage_pr), v(carAuction.lot_info.damage_sec));


  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={
          vin 
          + " - " 
          + carAuction.lot_info.title
          + ", aukcja: " + getAuctionName(carAuction.site)
          + ", nr LOT: " + carAuction.lot_id
          + ", data sprzedaży: " + formatDate(onlyDateNoTime(carAuction.sale_date))
          + ", cena sprzedaży: " + formattedPrice + "."
          } 
        />
      </Helmet>
      <Dialog
        onClose={() => {
          setFullscreenSrc("")
        }}
        open={fullscreenSrc !== ""}
      >
        <CardMedia
          component="img"
          image={fullscreenSrc}
          alt={carAuction.lot_info.title}
          className="rounded"
        />
      </Dialog>
      <Container maxWidth="lg" sx={{
        pb: 5
      }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          sx={{
            mb: 3,
            pt: { sm: 5, md: 3 }
          }}
        >
          <Grid item xs={12} sx={{ my: 2 }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>
                Historia sprzedaży
              </Typography>
              {carAuction.lot_info.make && <Typography>{carAuction.lot_info.make}</Typography>}
              {carAuction.lot_info.model && <Typography>{carAuction.lot_info.model}</Typography>}
              {carAuction.lot_info.year && <Typography>{carAuction.lot_info.year}</Typography>}
              {/* <Link color="inherit">
                @TODO
              </Link> */}
              <Typography color="text.primary">
                { vin }
              </Typography>

            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} display="flex" justifyContent="start" alignItems="end" flexDirection="column">
            {carAuction.lot_info?.link_img_hd.length > 0 &&
              <Tooltip title={"Pełny ekran"} arrow placement="top">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    position: "absolute",
                    m: 1,
                    zIndex: 10,
                    minWidth: "30px",
                    p: 1,
                    background: "#FFFFFFBB",
                    "&:hover": {
                      background: "white",
                      border: 0
                    },
                    color: "#223354",
                    border: 0
                  }}
                  onClick={() => {
                    setFullscreenSrc(carAuction.lot_info?.link_img_hd[carouselScreenShotIndex]);
                  }}
                >
                  <Fullscreen fontSize="small" />
                </Button>
              </Tooltip>
            }
            <Carousel
              autoPlay={true}
              interval={3000}
              fullHeightHover={false}
              navButtonsAlwaysVisible={false}
              animation="slide"
              // height="452px"
              navButtonsProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: "50%",
                  color: 'black',
                  // opacity: 1
                }
              }}
              navButtonsWrapperProps={{
                style: {
                  opacity: 1
                }
              }}
              NextIcon={<ChevronRight />}
              sx={{ width: "100%" }}
              onChange={(now) => {
                setCarouselScreenShotIndex(now);
              }}
            >
              {(carAuction.lot_info?.link_img_hd ?? ["/static/images/car-blank.png"]).map((image, key) => (
                <img
                  key={key}
                  src={image}
                  alt={carAuction.lot_info.title + "_" + key}
                  className="rounded"
                />
              ))}
            </Carousel>




            {/* <Tooltip title={
              "Kliknij, aby zmienić zdjęcie [" 
              + (imageIndex + 1) 
              + "/" 
              + carAuction.lot_info.link_img_hd.length 
              + "]"
              }>
              <Box 
                overflow="hidden" 
                className="rounded pointer" 
                onClick={() => {
                  if (imageIndex + 1 >= carAuction.lot_info.link_img_hd.length) {
                    setImageIndex(0);
                  } else {
                    setImageIndex(imageIndex + 1);
                  }
                }} 
                sx={{ 
                  width: "100%",
                  transition: "box-shadow 0.3s ease-in-out",
                  '&:hover': {
                    boxShadow: "0px 0px 12px 0px #8992a4"
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={
                    carAuction.lot_info.link_img_hd.length === 0 ?
                    "/static/images/car-blank.png" :
                    carAuction.lot_info.link_img_hd[imageIndex]
                  }
                  alt={carAuction.lot_info.title}
                  className={imageZoomClasses.imageZoom}
                />
              </Box>
            </Tooltip> */}
          </Grid>
          <Grid item xs={12} md={7}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Stack direction="row" spacing={2} display="flex" width="100%">
                  <Typography variant="h3" sx={{ mr: "auto", mb: 2 }}>Informacja o aukcji</Typography>
                  <Box sx={{ pt: 1 }}>
                    <CardMedia
                      component="img"
                      image={auctionLogo}
                      sx={{
                        width: "auto",
                        maxHeight: "30px"
                      }}
                    />
                  </Box>
                </Stack>
                <CardTable size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell className="muted">Aukcja</TableCell>
                      <TableCell align="right" className="notranslate">
                        {getAuctionName(carAuction.site)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="muted">Numer LOT</TableCell>
                      <TableCell align="right" className="notranslate">{carAuction.lot_id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="muted">Data sprzedaży</TableCell>
                      <TableCell align="right" className="notranslate">{formattedSoldDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="muted">Kraj</TableCell>
                      <TableCell align="right" className="notranslate">{
                      v(carAuction.buyer_country) !== MISSING 
                      && carAuction.buyer_country + " ("+(displayCountry(carAuction.buyer_country))+")"
                      }</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="muted">Oddział sprzedaży</TableCell>
                      <TableCell align="right" className="notranslate">{v(carAuction.lot_info.location)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="muted">Dokument pojazdu</TableCell>
                      <TableCell align="right" className="notranslate">{v(carAuction.lot_info.document)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="muted">Sprzedawca</TableCell>
                      <TableCell align="right" className="notranslate">{v(carAuction.lot_info.seller)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="primary" className="capitalize">Cena sprzedaży</Typography>
                      </TableCell>
                      <TableCell align="right" className="notranslate">
                        <Typography color="primary" fontSize="large" fontWeight="bold">
                          {formattedPrice}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </CardTable>
              </CardContent>
            </Card>
            <Card sx={{ backgroundColor: "primary.main", color: "white" }}>
              <CardContent sx={{ 
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                pb: 1
              }}>
                <Typography>Masz pytania? Skontaktuj się z nami!</Typography>
                <Box display="flex" alignItems="center">
                  <Email  sx={{ mr: 1 }} />
                  <Typography fontWeight="bold" fontSize="large" className="notranslate">
                    contact@bidhistory.vin
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Typography variant="h3" sx={{ mt: 5, mb: 3 }}>
          Charakterystyka pojazdu
        </Typography>
        <Grid container spacing={{ md: 5, xs: 0 }} sx={{ mb: 5 }}>
          <Grid item xs={12} md={4}>
            <CardTable size="small">
              <TableBody>
                <TableRow>
                  <TableCell className="muted">VIN</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.vin)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Marka</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.make)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Model</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.model)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Rok</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.year)}</TableCell>
                </TableRow>
                <TableRow className="lastBorder">
                  <TableCell className="muted">Kolor</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.color)}</TableCell>
                </TableRow>
              </TableBody>
            </CardTable>
          </Grid>
          <Grid item xs={12} md={4}>
            <CardTable size="small">
              <TableBody>
                <TableRow>
                  <TableCell className="muted">Przebieg</TableCell>
                  <TableCell align="right" className="notranslate">
                    {/* {console.log(carAuction.lot_info.odometer, Number(carAuction.lot_info.odometer))} */}
                    {carAuction.lot_info.odometer && (new Intl.NumberFormat().format(carAuction.lot_info.odometer) + " mil")}
                    {!carAuction.lot_info.odometer && MISSING}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Silnik</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.engine)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Skrzynia biegów</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.transmission)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Napęd</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.drive)}</TableCell>
                </TableRow>
                <TableRow className="lastBorder">
                  <TableCell className="muted">Paliwo</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.fuel)}</TableCell>
                </TableRow>
              </TableBody>
            </CardTable>
          </Grid>
          <Grid item xs={12} md={4}>
            <CardTable size="small">
              <TableBody>
                <TableRow>
                  <TableCell className="muted">Przyczyna uszkodzenia</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.loss)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Pierwotne uszkodzenia</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.damage_pr)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Pozostałe uszkodzenia</TableCell>
                  <Tooltip title={v(carAuction.lot_info.damage_sec)} placement="top">
                    <TableCell align="right" className="notranslate">{v(carAuction.lot_info.damage_sec)}</TableCell>
                  </Tooltip>
                </TableRow>
                <TableRow>
                  <TableCell className="muted">Stan</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.status)}</TableCell>
                </TableRow>
                <TableRow className="lastBorder">
                  <TableCell className="muted">Kluczyk</TableCell>
                  <TableCell align="right" className="notranslate">{v(carAuction.lot_info.keys)}</TableCell>
                </TableRow>
              </TableBody>
            </CardTable>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Box display="flex" alignItems="center">
              <Typography fontWeight="bold" sx={{ mr: 2 }}>Zaawansowany raport</Typography>
              <Link
                target="_blank"
                href={"https://epicvin.com/pl/check-vin-number-and-get-the-vehicle-history-report/checkout/" + vin}
                sx={{
                  borderRadius: "8px",
                  transition: "box-shadow 0.3s ease-in-out",
                  '&:hover': {
                    boxShadow: "0px 0px 12px 0px #8992a4"
                  }
                }}
              >
                <CardMedia
                  component="img"
                  src="/static/images/epic-vin.svg"
                  sx={{
                    height: "100%",
                    width: "178px",
                  }}
                />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Główne cechy pojazdu {title}
                </Typography>
                <Typography sx={{ mb: 1, pt: 2 }}>
                  {generateCarDescription(carAuction)}
                </Typography>
                <BulletList>
                  <ListItem>VIN: {carAuction.vin}</ListItem>
                  {v(carAuction.lot_info.status) !== MISSING && 
                    <ListItem>Stan pojazdu: <span className="notranslate">{carAuction.lot_info.status}</span></ListItem>
                  }
                  {damageInfo && 
                    <ListItem>Uszkodzenia pojazdu: <span className="notranslate">{damageInfo}</span></ListItem>
                  }
                  {v(carAuction.lot_info.odometer) !== MISSING && 
                    <ListItem>Przebieg pojazdu: <span className="notranslate">{carAuction.lot_info.odometer} mil</span></ListItem>
                  }
                  {v(carAuction.lot_info.document) !== MISSING && 
                    <ListItem>Dokument pojazdu: <span className="notranslate">{carAuction.lot_info.document}</span></ListItem>
                  }
                  {v(carAuction.lot_info.seller) !== MISSING && 
                    <ListItem>Sprzedawca pojazdu: <span className="notranslate">{carAuction.lot_info.seller}</span></ListItem>
                  }
                </BulletList>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} display="flex" justifyContent="center" sx={{ my: 3 }}>
            <Typography variant="h3">
              Podobne pojazdy
            </Typography>
          </Grid>
          {(similarAuctions.length > 0 ? similarAuctions : ["loading", "loading", "loading"]).map((item, key) => (
            <CarTile carAuction={item} key={key} />
          ))}
        </Grid>
      </Container>
    </>
  )
}