import React from 'react';
import { Box, Container, Divider, Link, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Email } from '@mui/icons-material';

const Footer = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container bgcolor="white">
        <Grid item xs={12}>
          <Container maxWidth="lg" sx={{ pb: 3 }}>
            <Grid container spacing={3} sx={{ my: 5 }}>
              <Grid item xs={12}>
                <img src="/static/images/bidhistory-logo.svg" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" className="capitalize" sx={{ mb: 1 }}>Skontaktuj się z nami</Typography>
                <Box display="flex" alignItems="center">
                  <Email />
                  <Typography fontWeight="bold" sx={{ ml: 1 }} className="notranslate">contact@bidhistory.vin</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={9} display="flex" sx={{
                flexDirection: {
                  xs: "column",
                  md: "row"
                },
                justifyContent: {
                  xs: "start",
                  md: "end"
                },
                alignItems: {
                  xs: "start",
                  md: "end"
                }
              }}>
                <Link href="/app/public-contract" sx={{ color: "#2B3236", fontWeight: "bold", mb: {xs: 1, md: 0}, mr: {xs: 0, md: 3} }} className="capitalize">
                  Umowa publiczna
                </Link>
                <Link href="/app/privacy-policy" sx={{ color: "#2B3236", fontWeight: "bold" }} className="capitalize">
                  Polityka prywatności
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mb: 3 }} />
                <Typography variant="subtitle2">
                  Wszelkie prawa zastrzeżone © 2023 bidhistory.vin
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Footer;