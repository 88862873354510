import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Cancel, CheckCircle, CheckCircleOutlined, DisabledByDefault, DoDisturbOn, DoDisturbOnOutlined } from '@mui/icons-material';
import { Alert, Button, IconButton, InputAdornment, LinearProgress, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { IResultPageData } from 'src/api/carAuctionsAPI';
import PriceInput from "./PriceInput";
import { formatDate } from "../../../components/CarTile/utils";

export interface IAdminTableRow {
  id: number,
  vin: string,
  lot_id: string,
  make: string,
  purchase_price: number,
  sale_date: string,
  display_auction: boolean,
}

interface IAdminTable {
  loading: boolean,
  rows: IAdminTableRow[],
  pageData: IResultPageData,
  onDisplayHideAuction: (id: number, display: boolean) => void,
  onPriceUpdate: (id: number, newPrice: number) => void,
  onChangePage: (pageNumber: number) => void,
  onChangePageSize: (pageSize: number) => void,
}

export default function AdminTable(props: IAdminTable) {


  // console.log(props);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" size="small" sx={{
        opacity: props.loading ? 0.5 : 1
      }}>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>VIN</TableCell>
            <TableCell align="center">LOT</TableCell>
            <TableCell align="center">Marka</TableCell>
            <TableCell align="center">Cena</TableCell>
            <TableCell align="center">Data sprzedaży</TableCell>
            <TableCell align="center">Widoczność</TableCell>
            {/* <TableCell align="center">Pokaż/Ukryj</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, key) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Typography fontSize="small">{
                  (props.pageData.page * props.pageData.size) - props.pageData.size + key + 1
                }.</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.vin === " " 
                ? <Typography color="secondary.main" fontStyle="italic">(empty)</Typography> 
                : <Link to={"/app/car/sale-history/" + row.vin} target="_blank">
                    {row.vin}
                  </Link>
                }
              </TableCell>
              <TableCell align="center">{row.lot_id}</TableCell>
              <TableCell align="center">{row.make}</TableCell>
              <TableCell align="center" sx={{ maxWidth: "200px" }}>
                <PriceInput
                  defaultPrice={row.purchase_price}
                  onPriceUpdate={(newPrice: number) => {
                    props.onPriceUpdate(row.id, newPrice);
                  }}
                />
                {/*<TextField */}
                {/*  value={row.purchase_price} */}
                {/*  size="small" */}
                {/*  InputProps={{ */}
                {/*    endAdornment: */}
                {/*    <InputAdornment position="end" sx={{ display: { xs: 'none', md: 'flex' } }}>*/}
                {/*      USD*/}
                {/*    </InputAdornment>,*/}
                {/*  }}*/}
                {/*  // inputProps={{ style: { textAlign: "center" } }}*/}
                {/*/>*/}
              </TableCell>
              <TableCell align="center">
                <Tooltip title={row.sale_date} arrow placement="top">
                  <Typography>{formatDate(row.sale_date)}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <Button 
                  disabled={props.loading}
                  onClick={() => {
                    if (props.loading) return;
                    // let confirmText;
                    // if (row.display_auction) {
                    //   confirmText = "Ukryj ";
                    // } else {
                    //   confirmText = "Aktywuj ";
                    // }
                    // confirmText += "aukcję? | VIN: " + row.vin + " LOT: " + row.lot_id;
                    // if (confirm(confirmText)) {
                      props.onDisplayHideAuction(row.id, !row.display_auction)
                    // }
                  }}
                >
                  {row.display_auction ? (
                    <Tooltip title="Aukcja jest dostępna" arrow placement="right">
                      <CheckCircleOutlined color="success" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Aukcja jest ukryta" arrow placement="right">
                      <DoDisturbOnOutlined color="error" />
                    </Tooltip>
                  )}
                </Button>
              </TableCell>
              {/* <TableCell align="center">
                <Button size="small">
                  {row.display_auction ? "Ukryj" : "Pokaż"}
                  </Button>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      {props.rows.length === 0 && props.loading && <LinearProgress sx={{ m: 5 }} />}

      {props.rows.length === 0 && !props.loading && <Alert severity="info">Brak wyników.</Alert>}

      {props.rows.length !== 0 && 
        <TablePagination
          component="div"
          count={props.pageData.totalResults}
          page={props.pageData.page - 1}
          onPageChange={(event, newPage) => {
            props.onChangePage(newPage + 1);
          }}
          rowsPerPage={props.pageData.size}
          onRowsPerPageChange={(event) => {
            props.onChangePageSize(parseInt(event.target.value, 10));
          }}
        />
      }
      
    </TableContainer>
  );
}