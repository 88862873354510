import { AuthError } from "../carAuctionsAPI";

export interface IHttpTransport {
  get: (request: IHttpRequest) => Promise<IHttpResponse>;
  post: (request: IHttpRequest) => Promise<IHttpResponse>;
  delete: (request: IHttpRequest) => Promise<IHttpResponse>;
  patch: (request: IHttpRequest) => Promise<IHttpResponse>;
}

export interface IHttpRequest {
  host?: string;
  path: string;
  body?: Object;
  queryString?: string;
  headers?: HeadersInit;
}

export interface IHttpResponse {
  method: keyof typeof HttpVerbs;
  [key: string]: any;
}

export enum HttpVerbs {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface IHttpClient {
  get: (request: IHttpRequest) => Promise<IHttpResponse>;
  post: (request: IHttpRequest) => Promise<IHttpResponse>;
  delete: (request: IHttpRequest) => Promise<IHttpResponse>;
  patch: (request: IHttpRequest) => Promise<IHttpResponse>;
  getHost: () => string;
}

export interface IHttpClientFactory {
  createClient: (host: string) => IHttpClient;
}

export const fetchTransport = (): IHttpTransport => {
  return {
    get: (request: IHttpRequest): Promise<IHttpResponse> => {
      return new Promise((resolve, reject) => {
        const headers = request.headers ?? {
          method: "GET",
        };
        fetch(
          (request.host ?? "") +
            request.path +
            (request.queryString ? "?" + request.queryString : ""),
          {
            headers
          }
        )
          .then(res => {
            if (!res.ok) {
              // reject(res);
              if (res.status === 401) {
                throw new AuthError();
              } else {
                throw new Error(res.statusText);
              }
            }
            return res.json();
          })
          .then(json => {
            resolve({
              method: "GET", // I'm not sure why this is here tbh
              ...json,
            });
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    post: (request: IHttpRequest): Promise<IHttpResponse> => {
      return new Promise((resolve, reject) => {
        const headers = request.headers ?? {};
        headers["Content-Type"] = "application/json";
        const body =
          request.body instanceof FormData
            ? request.body
            : JSON.stringify(request.body);

        fetch((request.host ?? "") + request.path, {
          method: "POST",
          credentials: "include",
          headers,
          body,
        })
          .then(async res => {
            if (!res.ok) {
              if (res.status === 401) {
                throw new AuthError();
              } else {
                throw new Error(res.statusText);
              }
            }
            return res.json();
          })
          .then(json => {
            resolve({
              method: "POST",
              ...json,
            });
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    patch: (request: IHttpRequest): Promise<IHttpResponse> => {
      return new Promise((resolve, reject) => {
        fetch((request.host ?? "") + request.path, {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request.body),
        })
          .then(async res => {
            if (!res.ok) {
              throw Error(res.statusText);
            }
            return res.json();
          })
          .then(json => {
            resolve({
              method: "PUT",
              ...json,
            });
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    delete: (request: IHttpRequest): Promise<IHttpResponse> => {
      return new Promise((resolve, reject) => {
        fetch((request.host ?? "") + request.path, {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async res => {
            //TODO: probably check for 200
            if (!res.ok) {
              throw Error(res.statusText);
            }
            resolve({
              method: "DELETE",
            });
          })
          .catch(e => {
            reject(e);
          });
      });
    },
  };
};

const HttpClient = (transport: IHttpTransport, host: string): IHttpClient => {
  return {
    get: (request: IHttpRequest): Promise<IHttpResponse> => {
      // @ts-ignore
      return transport.get({ host, ...request });
    },
    post: (request: IHttpRequest): Promise<IHttpResponse> => {
      // @ts-ignore
      return transport.post({ host, ...request });
    },
    patch: (request: IHttpRequest): Promise<IHttpResponse> => {
      // @ts-ignore
      return transport.patch({ host, ...request });
    },
    delete: (request: IHttpRequest): Promise<IHttpResponse> => {
      // @ts-ignore
      return transport.delete({ host, ...request });
    },
    getHost: (): string => {
      return host;
    },
  };
};

export const http = (transport: IHttpTransport): IHttpClientFactory => {
  return {
    createClient: (host): IHttpClient => {
      return HttpClient(transport, host);
    },
  };
};

export function upsert(
  httpClient: IHttpClient,
  path: string,
  body: Object,
  id?: string
): Promise<boolean> {
  const verb = id ? "patch" : "post";
  return new Promise(async (resolve, reject) => {
    try {
      await httpClient[verb]({
        path: path,
        body: body,
      });
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}
