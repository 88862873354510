import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import BaseLayout from 'src/layouts/BaseLayout';
import WebsiteLayout from "./layouts/WebsiteLayout";
import Home from './content/home';
import Manage from './content/admin/manage';
import ListingPage from "./content/listing-page";
import AdvancedSearch from './content/advanced-search';
import PrivacyPolicy from "./content/privacy-policy";
import NotFound from "./content/not-found";
import Login from "./content/admin/login";
// import SuspenseLoader from 'src/components/SuspenseLoader';
/** @TODO lazy loading */
// const Loader = (Component) => (props) =>
//   (
//     <Suspense fallback={<SuspenseLoader />}>
//       <Component {...props} />
//     </Suspense>
//   );

// Pages
// const Home = Loader(lazy(() => import('src/content/home')));
// const Home = ;

// const Status404 = Loader(
//   lazy(() => import('src/content/pages/Status/Status404'))
// );
// const Status500 = Loader(
//   lazy(() => import('src/content/pages/Status/Status500'))
// );
// const StatusComingSoon = Loader(
//   lazy(() => import('src/content/pages/Status/ComingSoon'))
// );
// const StatusMaintenance = Loader(
//   lazy(() => import('src/content/pages/Status/Maintenance'))
// );

const routes: RouteObject[] = [
  {
    path: '',
    element: <WebsiteLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/app/home" replace />
      },
      // {
      //   path: 'status',
      //   children: [
      //     {
      //       path: '',
      //       element: <Navigate to="404" replace />
      //     },
      //     {
      //       path: '404',
      //       element: <Status404 />
      //     },
      //     {
      //       path: '500',
      //       element: <Status500 />
      //     },
      //     {
      //       path: 'maintenance',
      //       element: <StatusMaintenance />
      //     },
      //     {
      //       path: 'coming-soon',
      //       element: <StatusComingSoon />
      //     }
      //   ]
      // },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: 'app',
    element: <WebsiteLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace/>
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'car/sale-history/:vin',
        element: <ListingPage />
      },
      {
        path: 'car/search',
        element: <AdvancedSearch />
      },
      {
        path: 'public-contract',
        element: <PrivacyPolicy />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      }
    ]
  },
  {
    path: 'admin',
    element: <WebsiteLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="manage" replace/>
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'manage',
        element: <Manage />
      }
    ]
  }
];

export default routes;
