import React from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";

export default function PrivacyPolicy() {


  return (
    <>
      <Container maxWidth="md" sx={{
        pb: 5
      }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          // spacing={3}
          sx={{
            pt: { sm: 5 }
          }}
        >
          <Grid item xs={12} sx={{ my: 5 }}>
            <Typography variant="h1" className="capitalize">Polityka prywatności</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Card sx={{ p: 2 }}>
              <CardContent>
                <Typography variant="h3" sx={{ my: 2 }}>Administrator danych osobowych</Typography>
                <ol>
                  <li>Administratorem danych osobowych (ADO) jest: bidhistory.vin.</li>
                </ol>

                <Typography variant="h3" sx={{ my: 2 }}>Przetwarzanie danych</Typography>
                <ol>
                  <li>Przetwarzanie danych osobowych odbywa się na podstawie Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych; Dz. U.UE.L.2016.119.1).</li>
                  <li>Państwa dane osobowe będą przetwarzane wyłącznie w celach kontaktowych (np. odpowiedzi na wiadomości email).</li>
                  <li>Zlecenie wykonania usługi/realizacji zlecenia przez Zleceniodawcę przesłane drogą elektroniczną i/lub papierową stanowi każdorazowo polecenie przetwarzania danych osobowych.</li>
                  <li>Jako ADO będziemy przetwarzać dane osobowe w zakresie niezbędnym dla wypełnienia prawnie usprawiedliwionych celów.</li>
                </ol>

                <Typography variant="h3" sx={{ my: 2 }}>Okres przetwarzania danych</Typography>
                <ol>
                  <li>Dane osobowe będą przetwarzane przez nas przez okres niezbędny do realizacji celów kontaktowych.</li>
                </ol>

                <Typography variant="h3" sx={{ my: 2 }}>Jakie dane zbieramy?</Typography>
                <ol>
                  <li>Zbieramy wyłącznie dane zwykłe, tj. imię, nazwisko i adres poczty elektronicznej.</li>
                  <li>Podanie tych danych jest dobrowolne.</li>
                </ol>

                <Typography variant="h3" sx={{ my: 2 }}>Komu przekazujemy dane?</Typography>
                <ol>
                  <li>Nie zamierzamy przekazywać nikomu Państwa danych.</li>
                </ol>

                <Typography variant="h3" sx={{ my: 2 }}>Jakie mają Państwo prawa?</Typography>
                <ol>
                  <li>Jako ADO, zgodnie z obowiązującymi przepisami, gwarantujemy Państwu możliwość:– dostępu do swoich danych osobowych (uzyskanie informacji o przetwarzanych przez nas danych oraz kopii danych),– sprostowania przetwarzanych przez nas danych,– ograniczenia przetwarzania danych,– usunięcia przetwarzania danych,– przeniesienia danych do innego administratora,– wniesienia skargi do organu nadzorczego, (Prezesa Urzędu Ochrony Danych Osobowych),– wpływu na zakres przetwarzania danych,– cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem,– sprzeciwu wobec przetwarzania danych (w tym profilowania) na potrzeby marketingu bezpośredniego.</li>
                  <li>Stosowny wniosek należy przekazać mailowo na adres contact@bidhistory.vin.</li>
                </ol>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}