import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { getCookie, setCookie } from "typescript-cookie";

function App() {
  const content = useRoutes(router);
  if (!getCookie("googtrans")) {
    setCookie('googtrans', '/pl/en', { sameSite: 'strict' });
    window.location.href = '/app/home';
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
