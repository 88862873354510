import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { ChevronRight } from "@mui/icons-material";

export default function NotFound() {

  return (
    <Container maxWidth="md" sx={{
      pb: 5,
      backgroundImage: "url('/static/images/map.png')",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "100px",
      backgroundSize: "contain"
    }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        // spacing={3}
        sx={{
          pt: { sm: 5 }
        }}
      >
        <Box sx={{ height: "100px" }}>&nbsp;</Box>
        <Grid item xs={12} sx={{ my: 5, textAlign: "center" }}>
          <Typography variant="h1" fontSize="120px">404</Typography>
          <Typography variant="h2" className="capitalize" sx={{ mb: 3 }}>Nie znaleziono strony</Typography>
          <Link href="/app/home">
            <Button>POWRÓT DO STRONY GŁÓWNEJ <ChevronRight sx={{ ml: 1 }} /></Button>
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ height: "100px" }}>&nbsp;</Box>
    </Container>
  )
}