import {
  Alert,
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem, Pagination,
  Select,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CarTile from "../../components/CarTile";
import { CarSearch } from "../../components/CarSearch";
import { MISSING } from "../listing-page";
import { fetchTransport, http } from "../../api/base/http";
import { API_HOST } from "../../api/vars";
import { CarAuctionsAPI, ICarAuction, IResultPageData, ISort } from "../../api/carAuctionsAPI";
import { formatAmount } from "../../components/CarTile/utils";
import { useSearchParams } from "react-router-dom";

export default function AdvancedSearch() {

  const httpClient = http(fetchTransport()).createClient(API_HOST);
  const carAuctionsAPI = CarAuctionsAPI(httpClient);

  const [foundAuctions, setFoundAuctions] = useState<ICarAuction[]>([]);
  const [foundAmount, setFoundAmount] = useState<number|null>(null);
  const [pagesAmount, setPagesAmount] = useState<number|null>(null);
  // const [pageData, setPageData] = useState<IResultPageData>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [totalAuctionsAmount, setTotalAuctionsAmount] = useState<number>();

  const [sortOption, setSortOption] = useState<string>("date-newest");
  const [searchParams, setSearchParams] = useSearchParams();
  const filterObjectFromParams = {};
  const keysToCheck = ['make', 'model', 'yearFrom', 'yearTo'];

  keysToCheck.forEach(key => {
    const value = searchParams.get(key);
    if (value) {
      filterObjectFromParams[key] = value;
    }
  });

  const [filterObject, setFilterObject] = useState<any>(filterObjectFromParams);

  const [firstRender, setFirstRender] = useState<boolean>(true);

  const search = () => {

    setIsSearching(true);
    setFoundAuctions([]);
    setFoundAmount(null);
    setPagesAmount(null);
    const filterBody = {};
    if (filterObject.make) {
      filterBody["lot_info__make"] = {
        operator: "=",
        value: filterObject.make
      }
    }
    if (filterObject.model) {
      filterBody["lot_info__model"] = {
        operator: "=",
        value: filterObject.model
      }
    }
    if (filterObject.yearFrom && filterObject.yearTo) {
      filterBody["lot_info__year"] = {
        operator: "between",
        value: [filterObject.yearFrom, filterObject.yearTo]
      }
    } else if (filterObject.yearFrom) {
      filterBody["lot_info__year"] = {
        operator: ">=",
        value: filterObject.yearFrom
      }
    } else if (filterObject.yearTo) {
      filterBody["lot_info__year"] = {
        operator: "<=",
        value: filterObject.yearTo
      }
    }
    if (Object.keys(filterBody).length === 0) {
      alert("Cannot search with empty filters");
      setIsSearching(true);
      setFoundAuctions([]);
      return;
    }
    let sort: ISort = {
      field: "lot_info__year",
      order: "desc"
    };
    switch (sortOption) {
      case "date-newest":
        sort = {
          field: "sale_date",
          order: "desc"
        }
        break;
      case "date-oldest":
        sort = {
          field: "sale_date",
          order: "asc"
        }
        break;
      case "price-highest":
        sort = {
          field: "purchase_price",
          order: "desc"
        }
        break;
      case "price-lowest":
        sort = {
          field: "purchase_price",
          order: "asc"
        }
        break;
    }
    carAuctionsAPI
      .filter(
        filterBody,
        {
          page: currentPage,
          size: 20
        },
        sort,
      )
      .then(result => {
        setFoundAmount(result.page.totalResults);
        setPagesAmount(result.page.totalPages);
        setFoundAuctions(result.carAuctions);
        setIsSearching(false);
        if (result.page.page !== currentPage) {
          console.error("Something went wrong. page != currentPage");
          alert("!");
        }
        // console.log(result);
      })
      .catch(e => {
        setIsSearching(false);
        console.error(e);
        // alert("Something went wrong...");
      })
  }

  const onSubmit = (
    make: string|null,
    model: string|null,
    yearFrom: string|null,
    yearTo: string|null
  ) => {
    setCurrentPage(1);
    setFilterObject({
      make: make,
      model: model,
      yearFrom: yearFrom,
      yearTo: yearTo
    });
  }

  useEffect(() => {
    if (firstRender) {
      carAuctionsAPI
        .auctionsAmount()
        .then(amount => {
          setTotalAuctionsAmount(amount)
        })
        .catch(error => {
          console.error(error);
        })
      setFirstRender(false);
      if (Object.keys(filterObject).length > 0) {
        // Searching from params
        setSearchParams(new URLSearchParams());
        search();
      }
    } else {
      search();
    }
  }, [filterObject, currentPage])

  useEffect(() => {
    // You can change sorting before search and it shouldn't trigger it
    // But once you have results - change should trigger search with new sorting
    if (!firstRender && foundAmount !== null && foundAmount > 0) {
      setCurrentPage(1);
      search();
    }
  }, [sortOption])


  return (
    <Container
      maxWidth="lg"
      sx={{ pb: 5 }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h3" sx={{ my: 3 }}>
            Historia sprzedaży
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ mb: 2 }}>Wyszukiwanie zaawansowane</Typography>
            </Grid>
            <CarSearch
              gridStack={true}
              showClearButton={true}
              onSubmit={onSubmit}
              defaultValues={filterObjectFromParams}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} md={8}>
          <Grid container spacing={2} sx={{ color: "#61696D" }}>
            <Grid item xs={12} display="flex" justifyContent="space-between" sx={{
              flexDirection: {
                xs: "column",
                md: "row"
              }
            }}>
              <Box display="flex" alignItems="center" sx={{ mb: { xs: 1, md: 0 } }}>
                Znaleziono: <Typography sx={{ ml: 1, color: "#2B3236" }}>{foundAmount !== null ? formatAmount(foundAmount) : MISSING}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                Sortuj po:
                <Select
                  value={sortOption}
                  onChange={(e, v) => {
                    setSortOption(e.target.value)
                  }}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  <MenuItem value="date-newest">
                    Data sprzedaży (od najnowszych)
                  </MenuItem>
                  <MenuItem value="date-oldest">
                    Data sprzedaży (od najstarszych)
                  </MenuItem>
                  <MenuItem value="price-highest">
                    Cena sprzedaży (od najwyższej)
                  </MenuItem>
                  <MenuItem value="price-lowest">
                    Cena sprzedaży (od najniższej)
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            {isSearching &&
              <Grid item xs={12}>
                <LinearProgress sx={{ m: 2 }} />
                {totalAuctionsAmount &&
                  <Box display="flex" justifyContent="center">
                    <Typography variant="subtitle1" textAlign="center">Szukanie spośród {formatAmount(totalAuctionsAmount)} aukcji... <br />Bardziej precyzyjne filtry szybciej wygenerują wynik.</Typography>
                  </Box>}
              </Grid>
            }
            {!isSearching && foundAmount === 0 &&
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle1">Nie znaleziono żadnych aukcji dla podanych filtrów.</Typography>
                </Box>
              </Grid>
            }
            {foundAuctions.length > 0 && foundAuctions.map((item, key) => (
              <CarTile
                carAuction={item}
                key={key}
                gridColumns={{
                  xs: 12,
                  sm: 12,
                  md: 6
                }}
              />
            ))}
            {foundAuctions.length > 0 && (
              <Grid item xs={12} display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Pagination
                  count={pagesAmount}
                  page={currentPage}
                  onChange={(e, v) => {
                    setCurrentPage(v)
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}