import { Button, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchTransport, http } from "../../../api/base/http";
import { API_HOST } from "../../../api/vars";
import { CarAuctionsAPI } from "../../../api/carAuctionsAPI";
import { deleteAuthToken, getAuthToken, setAuthToken } from "../../../utils/authentication";

export default function Login() {
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const httpClient = http(fetchTransport()).createClient(API_HOST);
  const carAuctionsAPI = CarAuctionsAPI(httpClient);


  const onSubmit = () => {
    if (user === '' || password === '') {
      setError("Wpisz e-mail i hasło");
      return;
    }
    setError("");
    setIsLoading(true);
    carAuctionsAPI
      .authenticate(user, password)
      .then((token) => {
        if (!token) {
          alert("Something went wrong")
        }
        setAuthToken(token);
        carAuctionsAPI
          .isAuthenticated()
          .then((authenticated) => {
            if (authenticated) {
              // All good!
              window.location.href = '/admin/manage';
            } else {
              setError("Failed authentication due to internal error");
              setIsLoading(false);
            }
          })
          .catch((e) => {
            setError("Failed authentication due to internal error");
            setIsLoading(false);
            console.error(e);
          })

        // setIsLoading(false);
        // console.log(token)
      })
      .catch((e) => {
        setTimeout(() => {
          setIsLoading(false);
          setError("Nieprawidłowy e-mail lub hasło");
        }, 500);
        console.error(e)
      })
  }

  useEffect(() => {
    if (getAuthToken()) {
      carAuctionsAPI
        .isAuthenticated()
        .then((authenticated) => {
          if (authenticated) {
            // All good!
            window.location.href = '/admin/manage';
          } else {
            deleteAuthToken();
          }
        })
        .catch((e) => {
          deleteAuthToken();
        })
    }
  }, []);

  return (
    <Container className="notranslate" maxWidth="md" sx={{
      pb: 5,
      backgroundImage: "url('/static/images/map.png')",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "50px",
      backgroundSize: "contain"
    }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
        sx={{
          py: 5,
          my: 5
        }}
      >
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography variant="h1" textAlign="center" sx={{ mb: 2 }}>
            Zaloguj się
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <TextField
            value={user}
            onChange={(e) => {
              setUser(e.target.value)
            }}
            label="E-mail"
            placeholder="Podaj e-mail"
            name="bidhistory-login"
            sx={{ width: "300px" }}
            error={error !== ""}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <TextField
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
            label="Hasło"
            placeholder="Podaj hasło"
            type="password"
            name="bidhistory-password"
            sx={{ width: "300px" }}
            error={error !== ""}
            helperText={error !== "" ? error : " "}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <CircularProgress size="25px" /> : "Zaloguj"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}